import {Col, Row} from 'react-bootstrap';
import TrackingCard from './components/TrackingCard';
import {useAppDispatch} from '../../hooks/hooks';
import {useEffect, useState} from 'react';
import {setLoading} from '../../store/userSlice';
import { getTrackingsList } from './Trackings.service';
import ItemsPagination from '../../UI/pagination/ItemsPagination';
import {TrackingType} from './interfaces/ITrackings';
import { useNavigate } from 'react-router-dom';



const UserTrackingListProfile = () => {
  const dispatch = useAppDispatch();

  const [trackings, setTrackings] = useState([]);
  const [lastPage, SetLastPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const [languagesSelected, setLanguagesSelected] = useState<any>([]);
  const [followUpTypeSelected, setFollowUpTypeSelected] = useState<any>([]);
  const [providerSelected, setProviderSelected] = useState<any>([]);
  const [priceSelected, setPriceSelected] = useState<any>([]);
  const [getFromRedirect, setGetFromRedirect] = useState<boolean>(true);
  
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    //setPage(page)
    
    dispatch(setLoading(true));
    let result = await getTrackingsList(
      page,
      title,
      languagesSelected,
      followUpTypeSelected,
      providerSelected,
      priceSelected
      );
    if (result.response) {
      
      setTrackings(result.response.data.data);
      SetLastPage(result.response.data.page_meta.last_page);
    }
    dispatch(setLoading(false));
    
  };



  return (
    <div>
      
      
      
        <Row>
          

        {trackings.map((tracking: TrackingType) => {
          return (
            <Col lg={4} className='card-item-container'>
                    <TrackingCard
                      tracking={tracking}
                      isPackage={tracking.tracking_type == 'multi'}
                      enrolled={true}
                    />
                  </Col>
                );
              })}
              {trackings.length ?
              <Col lg={12}>
            <div className="pagination-container">
              <ItemsPagination
                pages={lastPage}
                current={page}
                changePage={page => {
                  setPage(page);
                }}
              />
            </div>
            </Col>
            :
            ''
            }

            {trackings.length == 0
            ?
            <div className='text-center'>
              <img src="/logo.png" alt="img" width={70} />
              <h3 className="text-center mt-3">Sorry There are no data found</h3>
            </div>
            :
            ''
            }

              </Row>
            
            
          

          
    </div>
  );
};

export default UserTrackingListProfile;
