export const getDynamicLinkDomain = () => {
  
    let domainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'dev.hedaya.ca' ){
        domainName = 'https://test.hedaya.ca'
        
        
    }

    else if( hostName == 'test.hedaya.ca' ){
        domainName = 'https://test.hedaya.ca'
    }

    else if(hostName == 'prod.hedaya.ca' || hostName == 'hedaya.ca'){
        domainName = 'https://hedaya.ca'
    }
    
    else{
        domainName = 'https://dev.hedaya.ca'
    }
    
    
    return domainName
  
};
