import {Col, Container, Row, Modal} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/AddAvailabilitySettings.module.css';
import {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import {
  getAvailabilityStatus,
  getConsultationInfosMeetingLink,
  getPrices,
  setAvailabilityStatus,
  setMeetLinkActivitation,
  setPrices,
} from './Availability.service';
import {useNavigate} from 'react-router-dom';
import toast, {Toaster} from 'react-hot-toast';
import { getMeetLink } from '../Consultants/Consultants.service';

const AddAvailabilitySettings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean | null>(null);
  const [price30, setPrice30] = useState('0');
  const [price30EGP, setPrice30EGP] = useState('0');
  const [price60, setPrice60] = useState('0');
  const [price60EGP, setPrice60EGP] = useState('0');
  const [price90EGP, setPrice90EGP] = useState('0');
  const [price90, setPrice90] = useState('0');
  const [price120, setPrice120] = useState('0');
  const [price120EGP, setPrice120EGP] = useState('0');
  const [price150, setPrice150] = useState('0');
  const [price150EGP, setPrice150EGP] = useState('0');
  const [price180, setPrice180] = useState('0');
  const [price180EGP, setPrice180EGP] = useState('0');
  const [duration30, setDuration30] = useState(false);
  const [duration60, setDuration60] = useState(false);
  const [duration90, setDuration90] = useState(false);
  const [duration120, setDuration120] = useState(false);
  const [duration150, setDuration150] = useState(false);
  const [duration180, setDuration180] = useState(false);
  const [marginHours, setMarginHours] = useState('0');
  const [validation, setValidation] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [first_session_free, setFirst_session_free] = useState(0);
  const [activateSessionLink, setActivateSessionLink] = useState(0);
  const [cantDeletePeriod, setCantDeletePeriod] = useState(false);
  const [sessionCurrency, setSessionCurrency] = useState('USD');
  const [meetLink, setMeetLink] = useState('');


  const handleCopyClick = () => {
    toast.success('copied');
    // Create a temporary input element to copy the value
    const tempInput = document.createElement('input');
    tempInput.value = meetLink;
    document.body.appendChild(tempInput);

    // Select and copy the value
    tempInput.select();
    document.execCommand('copy');

    // Remove the temporary input
    document.body.removeChild(tempInput);
  };
  

  useEffect(() => {
    getDisable();
    ReadData();
    getMeetingLink();
  }, []);

  const ApplyData = async () => {
    setValidation('');
    if (duration30 && price30 == '0' && price30EGP == '0' ) {
      setValidation('Must add price for active periods');
    } else if (duration60 && price60 == '0' && price60EGP == '0') {
      setValidation('Must add price for active periods');
    } else if (duration90 && price90 == '0' && price90EGP == '0') {
      setValidation('Must add price for active periods');
    } else if (duration120 && price120 == '0' && price120EGP == '0') {
      setValidation('Must add price for active periods');
    } else if (duration150 && price150 == '0' && price150EGP == '0') {
      setValidation('Must add price for active periods');
    } else if (duration180 && price180 == '0' && price180EGP == '0') {
      setValidation('Must add price for active periods');
    }else if( sessionCurrency == 'BOTH' && duration30 && (price30 == '0' || price30 == '' ) && (price30EGP == '0' || price30EGP == '' ) ){
      toast.error('Must fill both session prices');
    }else if( sessionCurrency == 'BOTH' && duration60 && (price60 == '0' || price60 == '' ) && (price60EGP == '0' || price60EGP == '' ) ){
      toast.error('Must fill both session prices');
    } else if( sessionCurrency == 'BOTH' && duration90 && (price90 == '0' || price90 == '' ) && (price90EGP == '0' || price90EGP == '' ) ){
      toast.error('Must fill both session prices');
    }else if( sessionCurrency == 'BOTH' && duration120 && (price120 == '0' || price120 == '' ) && (price120EGP == '0' || price120EGP == '' ) ){
      toast.error('Must fill both session prices');
    } else if( sessionCurrency == 'BOTH' && duration150 && (price150 == '0' || price150 == '' ) && (price150EGP == '0' || price150EGP == '' ) ){
      toast.error('Must fill both session prices');
    } else if( sessionCurrency == 'BOTH' && duration180 && (price180 == '0' || price180 == '' ) && (price180EGP == '0' || price180EGP == '' ) ){
      toast.error('Must fill both session prices');
    }
    
    else {
      dispatch(setLoading(true));
      let data = new FormData();
      data.append('first_session_free', String(first_session_free));
      if (duration30) {
        data.append('duration30', '30');
        data.append('currency30', (price30 && (sessionCurrency == 'USD' || sessionCurrency == 'BOTH' )) ? price30 : '0' );
        data.append('currency30_egp', (price30EGP && (sessionCurrency == 'EGP' || sessionCurrency == 'BOTH' )) ? price30EGP : '0');
      }
      if (duration60) {
        data.append('duration60', '60');
        data.append('currency60', (price60 && (sessionCurrency == 'USD' || sessionCurrency == 'BOTH' )) ? price60 : '0' );
        data.append('currency60_egp', (price30EGP && (sessionCurrency == 'EGP' || sessionCurrency == 'BOTH' )) ? price60EGP : '0');
      }
      if (duration90) {
        data.append('duration90', '90');
        data.append('currency90', (price90 && (sessionCurrency == 'USD' || sessionCurrency == 'BOTH' )) ? price90 : '0' );
        data.append('currency90_egp', (price90EGP && (sessionCurrency == 'EGP' || sessionCurrency == 'BOTH' )) ? price90EGP : '0');
      }
      if (duration120) {
        data.append('duration120', '120');
        data.append('currency120', (price120 && (sessionCurrency == 'USD' || sessionCurrency == 'BOTH' )) ? price120 : '0' );
        data.append('currency120_egp', (price120EGP && (sessionCurrency == 'EGP' || sessionCurrency == 'BOTH' )) ? price120EGP : '0');
      }
      if (duration150) {
        data.append('duration150', '150');
        data.append('currency150', (price150 && (sessionCurrency == 'USD' || sessionCurrency == 'BOTH' )) ? price150 : '0' );
        data.append('currency150_egp', (price150EGP && (sessionCurrency == 'EGP' || sessionCurrency == 'BOTH' )) ? price150EGP : '0');
      }
      if (duration180) {
        data.append('duration180', '180');
        data.append('currency180', (price180 && (sessionCurrency == 'USD' || sessionCurrency == 'BOTH' )) ? price180 : '0' );
        data.append('currency180_egp', (price180EGP && (sessionCurrency == 'EGP' || sessionCurrency == 'BOTH' )) ? price180EGP : '0');
      }
      data.append('marginhours', marginHours);
      data.append('consultant_currency', sessionCurrency);
      console.log('dara', data);
      try {
        let result = await setPrices(data);
        console.log(result);
        if (result.response.status) {
          dispatch(setLoading(false));
          navigate(-1);
        } else {
          dispatch(setLoading(false));
          setCantDeletePeriod(true);
        }
      } catch (e) {
        console.log({e});
        dispatch(setLoading(false));
      }
    }
  };
  const ReadData = async () => {
    dispatch(setLoading(true));

    try {
      let result = await getPrices();
      
      if (result.response.status && result.response.data) {
        
        setSessionCurrency(result?.response?.data?.consultant_currency ?? 'USD' )

        setMarginHours(JSON.stringify(result?.response?.data?.marginhours));
        setFirst_session_free(result?.response?.data?.first_session_free);
        if (
          result?.response?.data?.duration30 &&
          result?.response?.data?.duration30 !== 0
        ) {
          setDuration30(true);
          setPrice30(JSON.stringify(result?.response?.data?.currency30));
          setPrice30EGP(JSON.stringify(result?.response?.data?.currency30_egp));
          
        }
        if (
          result?.response?.data?.duration60 &&
          result?.response?.data?.duration60 !== 0
        ) {
          setDuration60(true);
          setPrice60(JSON.stringify(result?.response?.data?.currency60));
          setPrice60EGP(JSON.stringify(result?.response?.data?.currency60_egp));
        }
        if (
          result?.response?.data?.duration90 &&
          result?.response?.data?.duration90 !== 0
        ) {
          setDuration90(true);
          setPrice90(JSON.stringify(result?.response?.data?.currency90));
          setPrice90EGP(JSON.stringify(result?.response?.data?.currency90_egp));
        }
        if (
          result?.response?.data?.duration120 &&
          result?.response?.data?.duration120 !== 0
        ) {
          setDuration120(true);
          setPrice120(JSON.stringify(result?.response?.data?.currency120));
          setPrice120EGP(JSON.stringify(result?.response?.data?.currency120_egp));
        }
        if (
          result?.response?.data?.duration150 &&
          result?.response?.data?.duration150 !== 0
        ) {
          setDuration150(true);
          setPrice150(JSON.stringify(result?.response?.data?.currency150));
          setPrice150EGP(JSON.stringify(result?.response?.data?.currency150_egp));
        }
        if (
          result?.response?.data?.duration180 &&
          result?.response?.data?.duration180 !== 0
        ) {
          setDuration180(true);
          setPrice180(JSON.stringify(result?.response?.data?.currency180));
          setPrice180EGP(JSON.stringify(result?.response?.data?.currency180_egp));
        }
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log({e});
    }
  };

  const getMeetingLink = async () => {
    dispatch(setLoading(true));

    try {
      let result = await getConsultationInfosMeetingLink();

      if (result.response.data) {
        console.log('meet seesin link' , result.response )
        setMeetLink(result.response.data)
        setActivateSessionLink(1)
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log({e});
    }
  };

  const setMeetingLinkActivitation = async () => {
    dispatch(setLoading(true));
    let data = new FormData();
    data.append('has_private_link', String(activateSessionLink));
    if( meetLink != '' && activateSessionLink ){
      data.append('link', meetLink);
    }
    let result = await setMeetLinkActivitation(data);
    if (result.response.status) {
      dispatch(setLoading(false));
      toast.success('Done');
    }else{
      toast.error(result.response.msg);
    }
  };

  const setDisable = async (i: any) => {
    dispatch(setLoading(true));
    let data = new FormData();
    data.append('disable', i);
    let result = await setAvailabilityStatus(data);
    if (result.response.status) {
      dispatch(setLoading(false));
      setShow(i);
      getDisable();
    }
  };
  const getDisable = async () => {
    let result = await getAvailabilityStatus();
    if (result.response.status) {
      setDisabled(result.response.data);
    }
  };

  return (
    <div className="mt-md-5 mb-5">
      <Toaster position="top-right" />
      <Container>
        <div className="text-center">
          <img src="/icons/hourglass.png" style={{maxWidth: '200px'}} />
          <h3 className="w-100 mb-3 font-weight-bold mt-3">
            Add Availability Settings
          </h3>
          <p className="text-font-muted">You can add more than one duration</p>
        </div>

        <Row>
          <Col md={{span: 8, offset: 2}}>
            <div
              className={`${classes.Container} d-flex justify-content-between align-items-center`}>
              <div>Enable Availability</div>
              <div>
                <div className={`${classes.enableAvailability}`}>
                  <input
                    type="checkbox"
                    id="enable-availabilty"
                    onChange={() => {
                      disabled ? setDisable(false) : setDisable(true);
                    }}
                    checked={!disabled}
                  />
                  <label id="enable-availabilty"></label>
                </div>
              </div>
            </div>
          </Col>

                 
          <Col md={{span: 8, offset: 2}}>
            <div className={`mt-4`}>
              <div>

                {/*    
                <div className='mb-4'>
                  <p>Select Price Type</p>
                  <Form.Select
                    value={sessionCurrency}
                   onChange={(e: any) => {
                   
                    setSessionCurrency(e.target.value)
                  }}

                  size="lg">
                    <option value={'USD'} >Dollar</option>
                    <option value={'EGP'} >Pound</option>
                    <option value={'BOTH'} >Both</option>
                  </Form.Select>
                </div>
                */}

                
                <div className={`d-flex justify-content-between`}>
                  <p>Session Duration</p>
                  <p>Session Price</p>
                </div>


                <div className={`d-flex justify-content-between availablity-container`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'30'}>
                      <Form.Check.Input
                        checked={duration30}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration30(!duration30);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'30 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>
                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'USD') ? 
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price30}
                        maxLength={4}
                        onChange={i => setPrice30(i.target.value)}
                      />{' '}
                      USD
                    </div>
                  </div>
                  : '' }

                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'EGP') ? 
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price30EGP}
                        maxLength={4}
                        onChange={i => setPrice30EGP(i.target.value)}
                      />{' '}
                      EGP
                    </div>
                  </div>
                  : '' }

                </div>
                <div className={`d-flex justify-content-between availablity-container`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'60'}>
                      <Form.Check.Input
                        checked={duration60}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration60(!duration60);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'60 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>
                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'USD') ? 
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price60}
                        maxLength={4}
                        onChange={i => setPrice60(i.target.value)}
                      />{' '}
                      USD
                    </div>
                  </div>
                  : '' }

                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'EGP') ? 
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price60EGP}
                        maxLength={4}
                        onChange={i => setPrice60EGP(i.target.value)}
                      />{' '}
                      EGP
                    </div>
                  </div>
                  : '' }

                </div>
                <div className={`d-flex justify-content-between availablity-container`}>
                  
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'90'}>
                      <Form.Check.Input
                        checked={duration90}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration90(!duration90);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'90 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>
                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'USD') ? 
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price90}
                        maxLength={4}
                        onChange={i => setPrice90(i.target.value)}
                      />{' '}
                      USD
                    </div>
                  </div>
                  : '' }

                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'EGP') ? 
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price90EGP}
                        maxLength={4}
                        onChange={i => setPrice90EGP(i.target.value)}
                      />{' '}
                      EGP
                    </div>
                  </div>
                  : '' }

                </div>
                <div className={`d-flex justify-content-between availablity-container`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'120'}>
                      <Form.Check.Input
                        checked={duration120}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration120(!duration120);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'120 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>
                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'USD') ?
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price120}
                        maxLength={4}
                        onChange={i => setPrice120(i.target.value)}
                      />{' '}
                      USD
                    </div>
                  </div>
                  : '' }

                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'EGP') ?
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price120EGP}
                        maxLength={4}
                        onChange={i => setPrice120EGP(i.target.value)}
                      />{' '}
                      EGP
                    </div>
                  </div>
                  : '' }

                </div>
                <div className={`d-flex justify-content-between availablity-container`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'150'}>
                      <Form.Check.Input
                        checked={duration150}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration150(!duration150);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'150 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>

                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'USD') ?
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price150}
                        maxLength={4}
                        onChange={i => setPrice150(i.target.value)}
                      />{' '}
                      USD
                    </div>
                  </div>
                  : '' }

                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'EGP') ?
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price150EGP}
                        maxLength={4}
                        onChange={i => setPrice150EGP(i.target.value)}
                      />{' '}
                      EGP
                    </div>
                  </div>
                  : '' }

                </div>
                <div className={`d-flex justify-content-between availablity-container`}>
                  <div className={`${classes.CheckBoxContainer}`}>
                    <Form.Check type="checkbox" id={'180'}>
                      <Form.Check.Input
                        checked={duration180}
                        type="checkbox"
                        isValid
                        onChange={() => {
                          setDuration180(!duration180);
                        }}
                      />
                      <Form.Check.Label>
                        <span style={{color: '#000'}}>{'180 Minutes'}</span>
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div>
                    <img
                      src="/icons/right-arrows.png"
                      style={{width: '50px'}}
                    />
                  </div>

                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'USD') ?
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price180}
                        onChange={i => setPrice180(i.target.value)}
                        maxLength={4}
                      />{' '}
                      USD
                    </div>
                  </div>
                  : '' }

                  { (sessionCurrency == 'BOTH' || sessionCurrency == 'EGP') ?
                  <div className={`${classes.PriceBoxContainer}`}>
                    <div>
                      <input
                        type="text"
                        value={price180EGP}
                        onChange={i => setPrice180EGP(i.target.value)}
                        maxLength={4}
                      />{' '}
                      EGP
                    </div>
                  </div>
                  : '' }

                </div>
              </div>
            </div>
          </Col>

          <Col md={{span: 8, offset: 2}}>
            <div
              className={`${classes.Container} d-flex justify-content-between align-items-center`}>
              <div>First session will be free</div>
              <div>
                <Form.Check type="checkbox" id={'is-free'}>
                  <Form.Check.Input
                    checked={first_session_free == 1}
                    type="checkbox"
                    isValid
                    onChange={() => {
                      setFirst_session_free(first_session_free ? 0 : 1);
                    }}
                  />
                  <Form.Check.Label></Form.Check.Label>
                </Form.Check>
              </div>
            </div>
          </Col>

          <Col md={{span: 8, offset: 2}} className='mt-3'>  
            <h6 className='my-2'>Choose Meeting System</h6>        
            <div
              className={`${classes.Container}`}>
              <div className='d-flex justify-content-between align-items-center'>  
                <h6>Use Nualim Meeting System</h6> 
                <div>
                      <input
                        checked={activateSessionLink == 0}
                        type="radio"
                        id="deactivateSession"
                        name="sessionActivation"
                        onChange={() => {
                          setActivateSessionLink(activateSessionLink ? 0 : 1);
                        }}
                        style={{ scale: '1.3' }}
                      />

                </div>
              </div>
              <p className='text-secondary'>This is the default link of Nualim System</p>

              <p className={`fontWeight-bold ${classes.HeaderTitle} `}>
                <span className='text-secondary'>Or</span>
              </p>

              <div className='d-flex justify-content-between align-items-center'>  
                <h6>Use your meeting system</h6> 
                <div>
                      <input
                        checked={activateSessionLink == 1}
                        type="radio"
                        id="activateSession"
                        name="sessionActivation"
                        onChange={() => {
                          setActivateSessionLink(activateSessionLink ? 0 : 1);
                        }}
                        style={{ scale: '1.3' }}
                      />

                </div>
              </div>
              <p className='text-secondary'>Replace defualt link with another external meeting link,zoom,google meet, etc...</p>


              <Form.Group className='mt-3 mb-1 d-flex align-items-center' style={{ position: 'relative'} } >
                <Form.Control
                type="text" 
                placeholder="Insert your meeting link"
                value={meetLink}
                onChange={e => setMeetLink(e.target.value)}
                style={{background: '#f8f8f8' }}        
                />
                <img
                  src="/session-paste.png"
                  width={25}
                  className="mx-2"
                  onClick={handleCopyClick}
                  style={{ cursor: 'pointer', position: 'absolute' , right: '5px' } }
                />
              </Form.Group>
              <div className='my-3' onClick={() => {setMeetingLinkActivitation() }}>
                <MainButton text="Submit" disabled={ activateSessionLink && meetLink == '' } />
              </div>

            </div>
          </Col>

          <Col md={{span: 8, offset: 2}}>
            <p className="text-font-muted mt-4 mb-4">
              You can stop the session booking by specifying the number of hours
              before it starts
            </p>
          </Col>

          <Col md={{span: 8, offset: 2}}>
            <div
              className={`${classes.TimeBoxContainer} d-flex justify-content-between`}>
              <div>
                <input
                  value={marginHours}
                  type="text"
                  onChange={i => setMarginHours(i.target.value)}
                  maxLength={4}
                />
              </div>
              <div>hour</div>
            </div>
          </Col>
          <Col md={{span: 8, offset: 2}}>
            <div className="mt-4 mb-4 danger-title">{validation}</div>
          </Col>
          <Col md={{span: 8, offset: 2}}>
            <MainButton text={'Done'} onClick={ApplyData} />
          </Col>
        </Row>
      </Container>

      {/*switch enable availabilty*/}
      <Modal show={show !== null} onHide={() => setShow(null)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <img src="/logo.png" style={{maxWidth: '100px'}} />
            <h5 className="w-100 mt-2">
              {show
                ? 'Sessions requests have been suspended'
                : 'Sessions requests have been opened again'}
            </h5>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div onClick={() => setShow(null)} className="w-100">
            <MainButton text={'Ok'} />
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={cantDeletePeriod}
        onHide={() => setCantDeletePeriod(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <img src="/logo.png" style={{maxWidth: '90px'}} />
            <h5 className="w-100 mt-4">
              You cannot delete this period until you delete its associated
              availability periods
            </h5>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div onClick={() => setCantDeletePeriod(false)} className="w-100">
            <MainButton text={'Ok'} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddAvailabilitySettings;
