import {Col, Row, Tab, Tabs} from 'react-bootstrap';
import '../style/ConsultantTabs.css';
import Language from '../../../UI/language/Language';
import TrackingCard from '../../../UI/tracking-card/TrackingCard';
import MainButton from '../../../UI/main-button/MainButton';
import {ConsultantItemProps} from '../interfaces/IConsultants';
import {useNavigate} from 'react-router';
import {useParams, useLocation} from 'react-router-dom';
import ConsultantFeedback from './ConsultantFeedback';
import CalenderContainer from '../../../components/calender/CalenderContainer';
import {SlotData} from '../../Booking/interface/IBooking';
import {getToCart} from '../../../store/CartSlice';
import {addCart, getConsultantSlots} from '../../Booking/Booking.service';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {useEffect, useState} from 'react';
import moment from 'moment';
import toast, {Toaster} from 'react-hot-toast';
import {colors} from '../../../constants/colors';
import ConsultantCourses from './ConsultantCourses';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {getSpecAvailability} from '../Consultants.service';
import {groupDatesByDayOfWeek} from '../../../helpers/groupDatesByDayOfWeek';
import {setLoading} from '../../../store/userSlice';
import ConsultantFollowUpSys from './ConsultantFollowUpSys';
import ConsultantAvailability from '../../Availability/ConsultantAvailability';
import {isUserLoggedIn} from '../../../auth/utiles';
import ConsultantInitiatives from './ConsultantInitiatives';
import ComeSoon from '../../../components/coming-soon/ComeSoon';
import ConsultantRecordedCourses from './ConsultantRecordedCourses';
const ConsultantTabs = ({consultant, survey, setTab}: ConsultantItemProps) => {
  const location = useLocation();
  console.log('consultantconsultant', consultant);

  const searchParams = new URLSearchParams(location.search);

  const [activeTab, setActiveTab] = useState(
    searchParams.has('consultant_profile') ? 'my-schedule' : 'about',
  );
  const [futureSlots, setFutureSlots] = useState<SlotData[]>([]);

  const navigate = useNavigate();
  const {id} = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showSchedule, setShowShedule] = useState(false);

  const checkAllIdsFutureExist = () => {
    const ids = futureSlots
      .filter(obj => obj.reserved !== 1 && obj.is_margined !== 1)
      .map(obj => obj.id);
    console.log('ids', ids);
    console.log(
      'ids2',
      ids.every(id => selectedSlots.findIndex(items => items.id == id) >= 0),
    );

    return ids.every(
      id => selectedSlots.findIndex(items => items.id == id) >= 0,
    );
  };

  const handleSelect = (key: string | null) => {
    if (key != null) {
      setTab!(key);
      setActiveTab(key);
      if (key == 'sessions') {
        getSlots(moment().locale('en').format('YYYY-MM-DD'));
      }
    }
  };

  const cart = useAppSelector(state => state.cart.data);
  const dispatch = useAppDispatch();
  const [selectedSlots, setSelectedSlots] = useState<SlotData[]>([]);
  const [savedDate, setSavedDate] = useState<any>(
    new Date(),
  );
  const getSlotTime = (time: any): any => {
    let hour = time?.slice(0, time.indexOf(':'));
    let min = time?.slice(time.indexOf(':') + 1, time.length);

    if (time) {
      return {
        date: ` ${hour > 12 ? hour - 12 : hour}:${min > 9 ? min : `${min}`} ${
          hour >= 12 ? 'PM' : 'AM'
        }`,
      };
    }
  };
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    if (activeTab == 'sessions') getSlots(savedDate);

    if (searchParams.has('consultant_profile')) setShowShedule(true);
  }, []);

  const slotColor = (
    id: number,
    reserved: number,
    consultation_session_id: number,
  ) => {
    if (thisSlotExistInCart(consultation_session_id)) return colors.blue;
    else if (selectedSlots.find(items => items.id == id))
      return colors.chamThirdColor;
    else if (reserved == 1) return colors.regularGray;
    else return colors.white;
  };

  const anySlotExistInCart = () => {
    return cart.find(i => i.model_name == 'ConsultationSession') ? true : false;
  };

  const textSlotColor = (
    id: number,
    reserved: number,
    consultation_session_id: number,
  ) => {
    if (thisSlotExistInCart(consultation_session_id)) return colors.white;
    else if (selectedSlots.find(items => items.id == id)) return colors.white;
    else if (reserved == 1) return '#505050';
    else return colors.font;
  };
  const thisSlotExistInCart = (consultation_session_id: number): any => {
    return cart.find(
      i =>
        i.model_id == consultation_session_id &&
        consultation_session_id != null,
    );
  };

  const getSlots = async (date: string) => {
    dispatch(setLoading(true));
    try {
      let data = new FormData();
      data.append('user_id', String(consultant?.id));
      data.append('date', date);
      let result = await getConsultantSlots(data);

      if (result.response.status) {
        setSlots(result.response.data);
        dispatch(setLoading(false));
      }
    } catch (e) {
      console.log({e});
      dispatch(setLoading(false));
    }
    // setLoadingCalendar(false);
  };

  const addToCart = async () => {
    if (isLoggedIn) {
      dispatch(setLoading(true));
      console.log('selectedSlots.....' , selectedSlots )
      
      let data: FormData = new FormData();
      let id: any = localStorage.getItem('id');
      selectedSlots.map((i: SlotData, index: number) => {
        data.append(
          `items[${index}][model_id]`,
          String(i.consultation_session_id),
        );
        data.append(`items[${index}][model_name]`, 'ConsultationSession');
        data.append(`items[${index}][user_id]`, String(id));
      });
      let result = await addCart(data);
      if (result.response.status) {
        setSelectedSlots([])
        dispatch(getToCart());
        dispatch(setLoading(false));
      }
      console.log('resultresultresultresult', result);
      
    } else {
      navigate(`/login`);
    }
  };

  const getSpecAvailabilityInFuture = async (
    start: string,
    duration: number,
  ) => {
    // dispatch(setLoad(true));
    // setFutureLoader(true);

    let time = moment(start).locale('en').format('HH:mm:ss');
    console.log('time', start);
    console.log('tim2', time);
    let result = await getSpecAvailability(time, duration, consultant?.id);
    console.log('xsdsfdsm,fndjkfhdskjds', result);

    if (result?.response?.status) {
      setFutureSlots(result.response?.data);
      // dispatch(setLoad(false));
      // multiSelect.current.open();
      handleShow();
    }
    // setFutureLoader(false);
  };
  const groupedDatesByDays = groupDatesByDayOfWeek(futureSlots);
  console.log('groupedDatesByDays', groupedDatesByDays);

  const checkIdsForOneDayExist = (day: string) => {
    console.log('daydayday,', day);
    for (const dateObj of groupedDatesByDays) {
      if (dateObj?.dayOfWeek == day) {
        const ids2 = dateObj?.dates
          ?.filter((obj: SlotData) => obj.reserved == 0 && obj.is_margined == 0)
          .map((ob: SlotData) => ob.id);
        // const ids = dateObj?.dates?.map(obj => obj.id);
        console.log('daydaydayids2,', ids2);
        return ids2.every(
          (id: any) => selectedSlots.findIndex(items => items.id == id) >= 0,
        );
      }
    }
  };

  const selectAllFutureSlotsForSpecDay = (day: string) => {
    let selected = [...selectedSlots];
    for (const dateObj of groupedDatesByDays) {
      if (dateObj?.dayOfWeek == day) {
        dateObj?.dates?.map((item: SlotData) => {
          if (checkIdsForOneDayExist(day)) {
            selected = selected?.filter(items => items.id != item?.id);
          } else {
            if (
              !thisSlotExistInCart(item?.consultation_session_id) &&
              item?.reserved == 0 &&
              item?.is_margined == 0 &&
              selectedSlots.findIndex(items => items.id == item.id) < 0
            ) {
              selected.push({
                ...item,
                model_id: item?.consultation_session_id,
                model_name: 'ConsultationSession',
              });
            }
          }
        });
        setSelectedSlots(selected);
      }
    }
  };

  const selectAllSlots = () => {
    let selected: SlotData[] = [];

    groupedDatesByDays?.map(item => {
      console.log('itemitemitemitemitemitem', item);
      item.dates?.map((item: SlotData) => {
        if (
          !thisSlotExistInCart(item?.consultation_session_id) &&
          item?.reserved == 0 &&
          item?.is_margined == 0
        ) {
          selected.push({
            ...item,
            model_id: item?.consultation_session_id,
            model_name: 'ConsultationSession',
          });
        }
      });
    });

    setSelectedSlots(selected);
  };

  const isLoggedIn = isUserLoggedIn();

  return (
    <div className="ConsultantTabsContainer">
      <Toaster position="top-right" />
      <Tabs
        defaultActiveKey={activeTab}
        id="uncontrolled-tab-example"
        onSelect={(key: string | null) => handleSelect(key)}
        className="consultant-tabs">
        {showSchedule && (
          <Tab eventKey="my-schedule" title="My Schedule">
            <Row>
              <ConsultantAvailability addHeaderOptions={false} />
            </Row>
          </Tab>
        )}

        <Tab eventKey="about" title="About">
          <Row>
            <div className="about-title">
              <h4>About</h4>
            </div>

            <p className="text-muted">{consultant?.about}</p>

            <div className="languages">
              <h4 className="mb-4">Languages</h4>

              <div>
                {consultant?.consultation_info_languages.map(i => {
                  return <Language name={i?.name} />;
                })}
              </div>
            </div>

            <div className="languages mt-4">
              <h4 className="mb-4">Fields</h4>
              <div>
                {consultant?.consultation_info_fields.map(i => {
                  return <Language name={i?.title} />;
                })}
              </div>
            </div>

            {consultant?.consultation_info_specialities[0] && (
              <div className="languages mt-4">
                <h4 className="mb-4">Specialist In</h4>
                <div>
                  {consultant?.consultation_info_specialities?.map(i => {
                    return <Language name={i?.title} />;
                  })}
                </div>
              </div>
            )}
          </Row>
        </Tab>
        <Tab eventKey="sessions" title="Private Lesson">
          {consultant?.consultation_info_disable == 1 ? (
            <h1 className="text-center">Can't Booking</h1>
          ) : (
            <Row>
              {/* <Col xs={9} className="text-center"> */}
              <h4 className="mb-3">
                <strong>Available Times</strong>
              </h4>

              <CalenderContainer
                date={savedDate}
                onChange={(i: any) => {
                  
                  setSavedDate(new Date(i));
                  getSlots(moment(i).locale('en').format('YYYY-MM-DD'));
                }}
              />

              <p className="mb-4 mt-3">
                <strong style={{color: 'red'}}>
                  You can only the first session for free, but you can not add
                  it to the cart or book other seesions
                </strong>
              </p>
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <Row className="w-100">
                  {slots.map((data: SlotData) => (
                    <Col md="4" className="mb-4">
                      <div
                        style={{
                          backgroundColor: slotColor(
                            data?.id,
                            data?.reserved,
                            data?.consultation_session_id,
                          ),
                        }}
                        className="d-flex align-items-center slotContainer">
                        <div
                          onClick={async () => {
                            if (
                              !thisSlotExistInCart(
                                data?.consultation_session_id,
                              )
                            ) {
                              let now = moment(new Date())
                                .locale('en')
                                .format('YYYY-MM-DD HH:m:s');
                              let nowMoment = moment.utc(
                                now,
                                'YYYY-MM-DD[T]HH:mm[Z]',
                              );

                              let todayDateAndTime = data.from_date;
                              let todayDateAndTimeMoment = moment.utc(
                                todayDateAndTime,
                                'YYYY-MM-DD[T]HH:mm[Z]',
                              );
                              if (data?.reserved == 1) {
                                toast.error('Session Already Booked');
                              } else if (data?.is_margined == 1) {
                                toast.error('Session Margin');
                              } else if (
                                moment(todayDateAndTimeMoment)
                                  .locale('en')
                                  .isAfter(nowMoment)
                              ) {
                                let arr = [...selectedSlots];

                                if (
                                  arr.findIndex(items => items.id == data.id) >=
                                  0
                                ) {
                                  setSelectedSlots(
                                    arr.filter(items => items.id != data.id),
                                  );
                                } else {
                                  setSelectedSlots([
                                    ...selectedSlots,
                                    {
                                      ...data,
                                      model_id: data?.consultation_session_id,
                                      model_name: 'ConsultationSession',
                                    },
                                  ]);
                                }
                              } else {
                                toast.error(
                                  'Can’t book session in previous time',
                                );
                              }
                            } else {
                              toast.error('Already In Cart');
                            }
                          }}
                          className="text-center"
                          style={{width: '75%'}}>
                          <div className="text-center">
                            <strong
                              style={{
                                color: textSlotColor(
                                  data?.id,
                                  data?.reserved,
                                  data?.consultation_session_id,
                                ),
                              }}>
                              {
                                getSlotTime(
                                  data?.from_date.slice(
                                    11,
                                    data.from_date.length - 3,
                                  ),
                                ).date
                              }
                            </strong>
                          </div>
                          <Row>
                            <Col
                              style={{
                                color: textSlotColor(
                                  data?.id,
                                  data?.reserved,
                                  data?.consultation_session_id,
                                ),
                              }}>
                              <img
                                src="/icons/clock.png"
                                alt="img"
                                width={20}
                                style={{marginRight: '5px'}}
                              />
                              {`${data.duration}Min`}
                            </Col>
                            <Col
                              style={{
                                color: textSlotColor(
                                  data?.id,
                                  data?.reserved,
                                  data?.consultation_session_id,
                                ),
                              }}>
                              {data?.price} {data?.user_currency}
                            </Col>
                          </Row>
                        </div>

                        <div
                          className="text-center rightBtn"
                          onClick={() => {
                            getSpecAvailabilityInFuture(
                              data?.from_date,
                              data?.duration,
                            );
                            setSelectedSlots([]);
                          }}>
                          More <br /> Sessions
                        </div>
                      </div>
                    </Col>
                  ))}
                  {slots.length != 0 && (
                    <Row style={{width: '100%'}}>
                      <Col>
                        <MainButton
                          text="Add To Cart"
                          onClick={addToCart}
                          disabled={selectedSlots?.length != 0 ? false : true}
                          style={{
                            backgroundColor:
                              selectedSlots.length != 0
                                ? colors.chamThirdColor
                                : colors.regularGray,
                            borderColor:
                              selectedSlots.length != 0
                                ? colors.chamThirdColor
                                : colors.regularGray,
                          }}
                        />
                      </Col>
                      {anySlotExistInCart() && (
                        <Col>
                          <MainButton
                            text="View Cart"
                            style={{
                              backgroundColor: colors.chamSecColor,
                              borderColor: colors.chamSecColor,
                            }}
                            onClick={() => navigate('/cart')}
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                  {slots.length == 0 && (
                    <div className='text-center'>
                    <img src="/logo.png" alt="img" width={90} />
                    <h3 className="text-center mt-3">Sorry There are no data found</h3>
                  </div>
                  )}
                </Row>
              </div>
            </Row>
          )}
        </Tab>
        <Tab eventKey="courses" title="Group Lesson">
          <ConsultantCourses tab={activeTab} />
        </Tab>
        <Tab eventKey="reviews" title="Reviews">
          <Row>
            {consultant && (
              <ConsultantFeedback
                ratings={consultant?.consultation_info_ratings}
                rating_stars={consultant?.consultation_info_rating_stars}
                feedback={consultant?.consultation_info_feedback}
              />
            )}
          </Row>
        </Tab>
        <Tab eventKey="follow-up-systems" title="Follow Up Systems">
          <ConsultantFollowUpSys tab={activeTab} />
        </Tab>
        <Tab eventKey="initiatives" title="Challenges">  
          <ConsultantInitiatives tab={activeTab} />
        </Tab>
        <Tab eventKey="recorded-courses" title="Courses">
          <ConsultantRecordedCourses tab={activeTab} />
        </Tab>
        
      </Tabs>
      <Modal show={show} onHide={handleClose}>
        {/*<Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>*/}
        <Modal.Body>
          <Form.Check type="checkbox" id={`all`}>
            <Form.Check.Input
              type="checkbox"
              isValid
              checked={checkAllIdsFutureExist()}
              onChange={() => {
                console.log('1');

                if (checkAllIdsFutureExist()) {
                  console.log('2');
                  setSelectedSlots([]);
                } else {
                  console.log('3');
                  selectAllSlots();
                }
              }}
            />
            <Form.Check.Label>Select All</Form.Check.Label>
          </Form.Check>

          <div
            className="allSessionsContainer"
            style={{
              maxHeight: '500px',
              overflowY: 'auto',
            }}>
            <div
              style={{
                marginTop: '10px',
                border: '2px solid #eee',
                borderRadius: '15px',
                padding: '1rem',
                marginRight: '10px',
              }}>
              {groupedDatesByDays.map(({dayOfWeek, dates}) => (
                <>
                  <Form.Check type="checkbox" id={dayOfWeek}>
                    <Form.Check.Input
                      checked={checkIdsForOneDayExist(dayOfWeek)}
                      type="checkbox"
                      isValid
                      onChange={() => {
                        selectAllFutureSlotsForSpecDay(dayOfWeek);
                      }}
                    />
                    <Form.Check.Label>
                      <span style={{color: '#2F5466'}}>{dayOfWeek}</span>
                    </Form.Check.Label>
                  </Form.Check>
                  {dates?.map(date => (
                    <div
                      onClick={() => {
                        if (date?.reserved !== 1 && date?.is_margined !== 1) {
                          if (
                            selectedSlots.findIndex(
                              items => items.id == date.id,
                            ) >= 0
                          ) {
                            setSelectedSlots(
                              selectedSlots.filter(
                                items => items.id != date.id,
                              ),
                            );
                          } else {
                            if (
                              !thisSlotExistInCart(
                                date?.consultation_session_id,
                              )
                            ) {
                              setSelectedSlots([
                                ...selectedSlots,
                                {
                                  ...date,
                                  model_id: date?.consultation_session_id,
                                  model_name: 'ConsultationSession',
                                },
                              ]);
                            }
                          }
                        }
                      }}
                      className="pop-up-des"
                      style={{
                        padding: '1rem',
                      }}>
                      <div className="d-flex align-items-center">
                        <Form.Check type="checkbox" id={dayOfWeek}>
                          <Form.Check.Input
                            type="checkbox"
                            disabled={
                              date?.reserved == 1 || date?.is_margined == 1
                            }
                            isValid
                            checked={
                              selectedSlots.findIndex(
                                items => items.id == date.id,
                              ) >= 0
                            }
                            onChange={() => {
                              if (
                                selectedSlots.findIndex(
                                  items => items.id == date.id,
                                ) >= 0
                              ) {
                                setSelectedSlots(
                                  selectedSlots.filter(
                                    items => items.id != date.id,
                                  ),
                                );
                              } else {
                                if (
                                  !thisSlotExistInCart(
                                    date?.consultation_session_id,
                                  )
                                ) {
                                  setSelectedSlots([
                                    ...selectedSlots,
                                    {
                                      ...date,
                                      model_id: date?.consultation_session_id,
                                      model_name: 'ConsultationSession',
                                    },
                                  ]);
                                }
                              }
                            }}
                          />
                        </Form.Check>
                        <div
                          style={{
                            padding: '1rem',
                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                            borderRadius: '10px',
                            marginLeft: '15px',
                            display: 'flex',
                            justifyContent: 'start',
                            flex: '100%',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}>
                          <div>
                            <img
                              src="/calendar.png"
                              alt="calendar"
                              style={{
                                width: '20px',
                                height: '20px',
                                verticalAlign: 'sub',
                              }}
                            />
                            <span>
                              {' '}
                              {`${moment(
                                date?.from,
                                'YYYY-MM-DD HH:mm:ss',
                              ).format('h:mm')} - ${moment(
                                date?.to,
                                'YYYY-MM-DD HH:mm:ss',
                              ).format('h:mm A')}`}{' '}
                            </span>
                          </div>
                          <span style={{marginLeft: '15px'}}>
                            {' '}
                            {moment(date?.date).format('DD MMM')}{' '}
                          </span>
                          {(thisSlotExistInCart(
                            date?.consultation_session_id,
                          ) ||
                            date?.reserved == 1) && (
                            <span style={{marginLeft: 40}}>
                              {thisSlotExistInCart(
                                date?.consultation_session_id,
                              )
                                ? 'inCart'
                                : date?.reserved == 1
                                ? 'Booked'
                                : null}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ))}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Row className="w-100">
            <Col>
              <MainButton text="Add To Cart" onClick={addToCart} />
            </Col>
            {anySlotExistInCart() && (
              <Col>
                <MainButton
                  text="View Cart"
                  style={{
                    backgroundColor: colors.chamThirdColor,
                    borderColor: colors.chamThirdColor,
                  }}
                  onClick={() => navigate('/cart')}
                />
              </Col>
            )}
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConsultantTabs;
