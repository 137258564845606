import {Col, Container, Modal, Row} from 'react-bootstrap';
import CalenderContainer from '../../components/calender/CalenderContainer';
import {useState, useEffect} from 'react';
import moment, {Moment} from 'moment';
import AvailabiltyItem from './components/AvailabiltyItem';
import {NavLink, useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import {
  getAvailability,
  deleteSlotsByIds,
  deleteRangeDate,
  getSpecAvailability,
  getPrices,
} from './Availability.service';
import {Slot} from './interface/IAvailabilty';
import MainButton from '../../UI/main-button/MainButton';
import CancelButton from '../../UI/cancel-button/CancelButton';
import Calendar from 'react-calendar';
import Form from 'react-bootstrap/Form';
import {groupDatesByDayOfWeek} from '../../helpers/groupDatesByDayOfWeek';
import {SlotData} from '../Booking/interface/IBooking';
import AvailabilityInstructions from '../Consultants/AvailabilityInstructions';

const ConsultantAvailability = ({addHeaderOptions}: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [show, setShow] = useState<boolean>(false);
  const [slots, setSlots] = useState<any>([]);
  const [selectedDateInCalendar, setSelectedDateInCalendar] = useState(
    moment().locale('en').format('YYYY-MM-DD'),
  );
  const [showAvailabiltyDeleteModal, setShowAvailabiltyDeleteModal] =
    useState<boolean>(false);
  const [showDeleteAllAvailability, setShowDeleteAllAvailability] =
    useState<boolean>(false);
  const [showDeleteAvailabilityPeriod, setShowDeleteAvailabilityPeriod] =
    useState<boolean>(false);
  const [warnDeleteInFuture, setWarnDeleteInFuture] = useState<boolean>(false);
  const [cantDeleteModal, setCantDeleteModal] = useState<boolean>(false);
  const [deleteType, setDeleteType] = useState(''); // single slot or all day or range or futute specific slot
  const [rangeDatesWillDelete, setRangeDatesWillDelete] = useState<any>([]); // start and end range will delete
  const [ifCantDelete, setIfCantDelete] = useState<boolean>(false); //check if slot can't delete or not
  const [removedSlots, setRemovedSlots] = useState<any[]>([]); // sessions will removed
  const [futureSlotTimeWillDelete, setFutureSlotTimeWillDelete] =
    useState<Moment>(moment()); // time send to get like it in future
  const [futureSlotPeriodWillDelete, setFutureSlotPeriodWillDelete] =
    useState<string>('');
  const [showDeleteSingleModal, setShowDeleteSingleModal] =
    useState<boolean>(false);
  const [confirmDeleteThisSession, setconfirmDeleteThisSession] =
    useState<boolean>(false);
  const [showDeleteFutureSessions, setShowDeleteFutureSessions] =
    useState<boolean>(false);
  const [confirmDeleteFutureSession, setConfirmDeleteFutureSession] =
    useState<boolean>(false);
  const [futureAVWillDelete, setFutureAVWillDelete] = useState<SlotData[]>([]); // future slots array get for deleted
  const [addPastAvAlert, setAddPastAvAlert] = useState<boolean>(false);
  const [emptyDurations, setEmptyDurations] = useState<boolean>(false);
  const [emptyDurationsModal, setEmptyDurationsModal] =
    useState<boolean>(false);
  const groupedDatesByDays = groupDatesByDayOfWeek(futureAVWillDelete);

  useEffect(() => {
    getSlots(selectedDateInCalendar);
    getCurrency();
  }, []);

  const getSlots = async (date: string) => {
    setSlots([]);
    dispatch(setLoading(true));
    let result = await getAvailability(
      moment(date).locale('en').format('DD-MM-YYYY'),
    );
    if (result.response.status) {
      setSlots(result?.response?.data);
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
    }
  };
  const deletedRangeChanged = (i: any) => {
    setRangeDatesWillDelete([
      {
        start: moment(i[0]).format('YYYY-MM-DD'),
        end: moment(i[1]).format('YYYY-MM-DD'),
      },
    ]);
  };
  const deleteSingleSlot = () => {
    setShowDeleteSingleModal(false);
    if (ifCantDelete) {
      setCantDeleteModal(true);
    } else {
      setDeleteType('singleSlot');
      // setconfirmDeleteThisSession(true);
      setShowDeleteAllAvailability(true);
    }
  };
  const deleteAllDay = () => {
    if (
      moment(selectedDateInCalendar, 'YYYY-MM-DD').isBefore(moment()) &&
      !moment(selectedDateInCalendar, 'YYYY-MM-DD').isSame(moment(), 'day')
    ) {
      setWarnDeleteInFuture(false);
      setCantDeleteModal(true);
    } else {
      setDeleteType('singleDay');
      setRangeDatesWillDelete([
        {start: selectedDateInCalendar, end: selectedDateInCalendar},
      ]);
      setShowAvailabiltyDeleteModal(false);
      setShowDeleteAllAvailability(true);
    }
  };
  const RemoveSlotsByIds = async () => {
    let removedIds: string = removedSlots.join(',');

    let result = await deleteSlotsByIds(removedIds);
    if (result?.response?.status) {
      getSlots(selectedDateInCalendar);
      setRemovedSlots([]);
      setShowDeleteAllAvailability(false);
    }
  };
  const removeSlotsByDateRange = async () => {
    let start = moment(rangeDatesWillDelete[0]?.start)
      .locale('en')
      .format('DD-MM-YYYY');
    let end = moment(rangeDatesWillDelete[0]?.end)
      .locale('en')
      .format('DD-MM-YYYY');
    let result = await deleteRangeDate(start, end);
    if (result.response.status) {
      setShowDeleteAllAvailability(false);
      getSlots(selectedDateInCalendar);
    }
  };
  const getSpecAvailabilityInFuture = async (dateTime: Moment) => {
    // setFutureAVInFutureLoader(true);
    let time = moment(dateTime).locale('en').format('HH:mm:ss');
    let result = await getSpecAvailability(futureSlotPeriodWillDelete, time);
    if (result?.response?.status) {
      setFutureAVWillDelete(result?.response?.data);
    }
    // setFutureAVInFutureLoader(false);
  };
  const checkAllIdsFutureExist = () => {
    const ids = futureAVWillDelete?.map(obj => obj.id);

    return ids.every(id => removedSlots?.includes(id));
  };
  const checkIdsForOneDayExist = (day: string) => {
    for (const dateObj of groupedDatesByDays) {
      if (dateObj?.dayOfWeek == day) {
        const ids = dateObj?.dates?.map(obj => obj.id);
        console.log('idsids', ids);

        return ids.every(id => removedSlots?.includes(id));
      }
    }
  };
  const selectAllFutureSlotsForSpecDay = (day: string) => {
    let removeSlotIds = [...removedSlots];
    for (const dateObj of groupedDatesByDays) {
      if (dateObj?.dayOfWeek == day) {
        dateObj?.dates?.map(item => {
          if (checkIdsForOneDayExist(day)) {
            removeSlotIds = removeSlotIds?.filter(items => items != item?.id);
          } else {
            if (!removedSlots?.includes(item.id)) {
              removeSlotIds.push(item.id);
            }
          }
        });
        setRemovedSlots(removeSlotIds);
        console.log('removeSlotIdsremoveSlotIds', removeSlotIds);
      }
    }
  };
  const selectAllSlots = () => {
    let removed: number[] = [];
    groupedDatesByDays?.map(item => {
      item.dates?.map(item => {
        removed.push(item.id);
      });
    });

    setRemovedSlots(removed);
  };

  const selectSingleAddAvalability = () => {
    // check validation before add single av if before today or not
    if (
      moment(selectedDateInCalendar, 'YYYY-MM-DD').isBefore(moment()) &&
      !moment(selectedDateInCalendar, 'YYYY-MM-DD').isSame(moment(), 'day')
    ) {
      setShow(false);
      setAddPastAvAlert(true);
    } else {
      navigate('/consultants/add/single/session/1000', {
        state: {
          daySelected: moment(selectedDateInCalendar).locale('en').format('YYYY-MM-DD'),
        },
      });
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getCurrency = async () => {
    let result = await getPrices();
    if (result?.response?.status) {
      if (result?.response?.data == null) {
        console.log('result?.response', result?.response);
        setEmptyDurations(true);
      } else if (
        result?.response?.data?.duration30 == 0 &&
        result?.response?.data?.duration60 == 0 &&
        result?.response?.data?.duration90 == 0 &&
        result?.response?.data?.duration120 == 0 &&
        result?.response?.data?.duration150 == 0 &&
        result?.response?.data?.duration180 == 0
      ) {
        setEmptyDurations(true);
      }
    }
  };

  return (
    <div className="mt-md-5">
      <AvailabilityInstructions
        show={showModal}
        onHide={handleCloseModal}
        handleClose={closeModal}
      />
      <Container>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h3 className="text-center">Availabilty</h3>
            <span
              onClick={handleShowModal}
              //onClick={() => navigate('/consultant/booked-sessions')}
              style={{
                color: '#2F5466',
                border: '1px solid #2F5466',
                borderRadius: '15px',
                padding: '3px 20px',
                fontSize: '20px',
                margin: '0 10px',
                cursor: 'pointer',
              }}>
              Learn
            </span>
          </div>
          {addHeaderOptions && (
            <div className="d-flex">
              <div
                onClick={() =>
                  emptyDurations ? setEmptyDurationsModal(true) : setShow(true)
                }
                style={{
                  borderRadius: '15px',
                  border: '2px solid #2F5466',
                  padding: '10px',
                  cursor: 'pointer',
                }}>
                <img src="/icons/addStory.png" style={{width: '30px'}} />
              </div>

              <div
                style={{
                  borderRadius: '15px',
                  border: '2px solid #2F5466',
                  padding: '10px',
                  cursor: 'pointer',
                  margin: '0px 7px',
                }}
                onClick={() =>
                  emptyDurations
                    ? setEmptyDurationsModal(true)
                    : setShowAvailabiltyDeleteModal(true)
                }>
                <img
                  src="/icons/deleteIconActive.png"
                  style={{width: '30px'}}
                />
              </div>

              <div
                onClick={() => {
                  navigate('/consultant/add-availability-settings');
                }}
                style={{
                  borderRadius: '15px',
                  border: '2px solid #2F5466',
                  padding: '10px',
                  cursor: 'pointer',
                }}>
                <img src="/icons/settings.png" style={{width: '30px'}} />
              </div>
            </div>
          )}
        </div>

        <CalenderContainer
          date={selectedDateInCalendar}
          onChange={(i: any) => {
            setSelectedDateInCalendar(
              moment(i).locale('en').format('YYYY-MM-DD'),
            );
            getSlots(i);
          }}
        />

        <div className="availabilty-container mt-5">
          <h3 className="mb-3">Schedule</h3>
          <div className="availabilty-items">
            <Row>
              {slots.length > 0 ? (
                slots.map((slot: Slot, i: number) => (
                  <Col xs={6} md={4} xl={3} key={i}>
                    <AvailabiltyItem
                      Slot={slot}
                      deleteSlot={i => {
                        if (
                          //all slots before now
                          moment(
                            `${selectedDateInCalendar} ${i?.from}`,
                          ).isBefore(moment(new Date()))
                        ) {
                          setWarnDeleteInFuture(false);
                          setIfCantDelete(true);
                        } else if (
                          //all reserved slots in future
                          i.reserved == 1 &&
                          moment(`${selectedDateInCalendar} ${i?.to}`).isAfter(
                            moment(new Date()),
                          )
                        ) {
                          setWarnDeleteInFuture(true);
                          setIfCantDelete(true);
                        } else {
                          setIfCantDelete(false);
                        }

                        setRemovedSlots([i?.id]);
                        setFutureSlotTimeWillDelete(
                          moment(`${selectedDateInCalendar} ${i?.from}`).locale(
                            'en',
                          ),
                        );
                        setFutureSlotPeriodWillDelete(i?.duration);
                        setShowDeleteSingleModal(true);
                      }}
                    />
                  </Col>
                ))
              ) : (
                <div className="text-center mt-4 mb-4">
                  <img
                    src="/icons/alarm-clock.png"
                    style={{maxWidth: '200px'}}
                  />
                  <h3>There is no slots for this day</h3>
                  <p className="text-muted">Start adding your availability</p>
                </div>
              )}
            </Row>
          </div>
        </div>
      </Container>

      {/*Add Availability pop up*/}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">Add Availability</h3>
            <img src="/icons/alarm-clock.png" style={{maxWidth: '150px'}} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="mb-2 w-100">
            <div
              style={{
                backgroundColor: '#2F5466',
                border: '2px solid #2F5466',
                borderRadius: '15px',
                padding: '10px 40px',
                color: '#fff',
                textAlign: 'center',
              }}>
              <NavLink
                to="/consultants/add/availablilty/1"
                className={`text-white cursor-pointer`}>
                <h6 className="mb-0">Add Future Availability</h6>
              </NavLink>
            </div>
          </div>

          <div className="mb-2 w-100">
            <div
              style={{
                backgroundColor: '#2F5466',
                border: '2px solid #2F5466',
                borderRadius: '15px',
                padding: '10px 40px',
                color: '#fff',
                textAlign: 'center',
              }}>
              {/* <NavLink
                to="/consultants/add/single/session/1000"
                className={`text-white`}> */}
              <h6
                className="mb-0 cursor-pointer"
                onClick={selectSingleAddAvalability}>
                Add Single Session
              </h6>
              {/* </NavLink> */}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/*Can't add av in past dates*/}
      <Modal
        show={addPastAvAlert}
        onHide={() => setAddPastAvAlert(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              You Can't add Availability In The Past Dates
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div onClick={() => setAddPastAvAlert(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>
      {/*Delete availabilty*/}
      <Modal
        show={showAvailabiltyDeleteModal}
        onHide={() => setShowAvailabiltyDeleteModal(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">Delete Availability</h3>
            <img src="/icons/delete.png" style={{maxWidth: '150px'}} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="mb-2 w-100">
            <div
              style={{
                backgroundColor: '#2F5466',
                border: '2px solid #2F5466',
                borderRadius: '15px',
                padding: '10px 40px',
                color: '#fff',
                textAlign: 'center',
              }}>
              <NavLink
                to="#"
                onClick={() => {
                  setShowAvailabiltyDeleteModal(false);
                  // setShowDeleteAllAvailability(true);
                  deleteAllDay();
                }}
                className={`text-white`}>
                <h6 className="mb-0">
                  Delete all sessions for the selected day
                </h6>
              </NavLink>
            </div>
          </div>

          <div className="mb-2 w-100">
            <div
              style={{
                backgroundColor: '#2F5466',
                border: '2px solid #2F5466',
                borderRadius: '15px',
                padding: '10px 40px',
                color: '#fff',
                textAlign: 'center',
              }}>
              <NavLink
                to="#"
                onClick={() => {
                  setDeleteType('dateRange');
                  setShowAvailabiltyDeleteModal(false);
                  setShowDeleteAvailabilityPeriod(true);
                }}
                className={`text-white`}>
                <h6 className="mb-0">
                  Delete availability for a period of time
                </h6>
              </NavLink>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/*Delete all availabilty for selected day*/}
      <Modal
        show={showDeleteAllAvailability}
        onHide={() => setShowDeleteAllAvailability(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        {deleteType == 'dateRange' ? (
          <Modal.Body>
            <div
              className="text-center"
              style={{fontSize: '1.3rem', marginTop: '2rem'}}>
              <p className="w-100 mb-2">You will delete all availability</p>
              <p className="mb-2">
                <span style={{width: '50%', display: 'inline-block'}}>
                  From :
                </span>
                <span
                  style={{width: '50%', display: 'inline-block'}}
                  className="secondry-color">
                  {` ${moment(rangeDatesWillDelete[0]?.start)
                    .locale('en')
                    .format('YYYY-MM-DD')} `}
                </span>
              </p>

              <p className="mb-0">
                <span style={{width: '50%', display: 'inline-block'}}>
                  To :
                </span>
                <span
                  style={{width: '50%', display: 'inline-block'}}
                  className="secondry-color">
                  {` ${moment(rangeDatesWillDelete[0]?.end)
                    .locale('en')
                    .format('YYYY-MM-DD')} `}
                </span>
              </p>
            </div>
          </Modal.Body>
        ) : deleteType == 'singleDay' ? (
          <Modal.Body>
            <div className="text-center">
              <h3 className="w-100 mb-4 font-weight-bold">
                You will delete all availability for
              </h3>
              <h5 className="secondry-color">{selectedDateInCalendar}</h5>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div className="text-center">
              <h3 className="w-100 mb-4 font-weight-bold">
                {deleteType == 'futureSpecificSlot'
                  ? 'You Will Delete All The Un Booked Sessions And You Must Cancel Any Booked Session To Can Delete It'
                  : 'Are You Sure That You Want To Delete The Selected Session ??'}
              </h3>
            </div>
          </Modal.Body>
        )}

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={'Delete'}
              onClick={() => {
                deleteType == 'singleSlot' || deleteType == 'futureSpecificSlot'
                  ? RemoveSlotsByIds()
                  : removeSlotsByDateRange();
              }}
            />
          </div>
          <div
            onClick={() => setShowDeleteAllAvailability(false)}
            className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Delete all availabilty for period*/}
      <Modal
        show={showDeleteAvailabilityPeriod}
        onHide={() => setShowDeleteAvailabilityPeriod(false)}
        centered>
        <Modal.Header>
          <Modal.Title>
            <h2 className="align-text-center">Select Range</h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Calendar selectRange={true} onChange={deletedRangeChanged} />
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={'Delete'}
              onClick={() => {
                setShowDeleteAvailabilityPeriod(false);
                setShowDeleteAllAvailability(true);
              }}
            />
          </div>
          <div
            onClick={() => setShowDeleteAvailabilityPeriod(false)}
            className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Can't Delete in past dates*/}
      <Modal
        show={cantDeleteModal}
        onHide={() => setCantDeleteModal(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              {warnDeleteInFuture
                ? `You Can't Delete Reserved Availability`
                : `You Can't Delete Availability In The Past Dates`}
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div onClick={() => setCantDeleteModal(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>
      {/*Delete availabilty single card*/}

      <Modal
        show={showDeleteSingleModal}
        onHide={() => setShowDeleteSingleModal(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">Delete Availability</h3>
            <img src="/icons/delete.png" style={{maxWidth: '150px'}} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="mb-2 w-100">
            <div
              style={{
                backgroundColor: '#2F5466',
                border: '2px solid #2F5466',
                borderRadius: '15px',
                padding: '10px 40px',
                color: '#fff',
                textAlign: 'center',
              }}>
              <NavLink
                to="#"
                onClick={() => {
                  deleteSingleSlot();
                }}
                className={`text-white`}>
                <h6 className="mb-0">Delete this session</h6>
              </NavLink>
            </div>
          </div>

          <div className="mb-2 w-100">
            <div
              style={{
                backgroundColor: '#2F5466',
                border: '2px solid #2F5466',
                borderRadius: '15px',
                padding: '10px 40px',
                color: '#fff',
                textAlign: 'center',
              }}>
              <NavLink
                to="#"
                onClick={() => {
                  setShowDeleteSingleModal(false);
                  setRemovedSlots([]);
                  setFutureAVWillDelete([]);
                  getSpecAvailabilityInFuture(futureSlotTimeWillDelete);
                  setShowDeleteFutureSessions(true);
                }}
                className={`text-white`}>
                <h6 className="mb-0">Delete future session</h6>
              </NavLink>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/*Confirm delete this session*/}
      <Modal
        show={confirmDeleteThisSession}
        onHide={() => setconfirmDeleteThisSession(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              Are you sure that you want to delete the selected session ?
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton text={'Delete'} />
          </div>
          <div
            onClick={() => setconfirmDeleteThisSession(false)}
            className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>

      {/*list to delete future sessions*/}
      <Modal
        show={showDeleteFutureSessions}
        onHide={() => setShowDeleteFutureSessions(false)}>
        <Modal.Body>
          <Form.Check type="checkbox" id={`all`}>
            <Form.Check.Input
              type="checkbox"
              isValid
              checked={checkAllIdsFutureExist()}
              onChange={() => {
                if (checkAllIdsFutureExist()) {
                  setRemovedSlots([]);
                } else {
                  selectAllSlots();
                }
              }}
            />
            <Form.Check.Label>Select All</Form.Check.Label>
          </Form.Check>

          <div
            className="allSessionsContainer"
            style={{
              maxHeight: '500px',
              overflowY: 'auto',
            }}>
            <div
              style={{
                marginTop: '10px',
                border: '2px solid #eee',
                borderRadius: '15px',
                padding: '1rem',
                marginRight: '10px',
              }}>
              {groupedDatesByDays.map(({dayOfWeek, dates}) => (
                <>
                  <Form.Check type="checkbox" id={dayOfWeek}>
                    <Form.Check.Input
                      checked={checkIdsForOneDayExist(dayOfWeek)}
                      type="checkbox"
                      isValid
                      onChange={() => {
                        selectAllFutureSlotsForSpecDay(dayOfWeek);
                      }}
                    />
                    <Form.Check.Label>
                      <span style={{color: '#2F5466'}}>{dayOfWeek}</span>
                    </Form.Check.Label>
                  </Form.Check>
                  {dates?.map(date => (
                    <div
                      // key={i}
                      onClick={() => {
                        if (removedSlots?.includes(date.id))
                          setRemovedSlots(
                            removedSlots?.filter(items => items != date.id),
                          );
                        else {
                          setRemovedSlots([...removedSlots, date.id]);
                        }
                      }}
                      className="pop-up-des"
                      style={{
                        padding: '1rem',
                      }}>
                      <div className="d-flex align-items-center">
                        <Form.Check type="checkbox" id={dayOfWeek}>
                          <Form.Check.Input
                            type="checkbox"
                            isValid
                            checked={removedSlots?.includes(date.id)}
                            onChange={() => {
                              if (removedSlots?.includes(date.id))
                                setRemovedSlots(
                                  removedSlots?.filter(
                                    items => items != date.id,
                                  ),
                                );
                              else {
                                setRemovedSlots([...removedSlots, date.id]);
                              }
                            }}
                          />
                        </Form.Check>
                        <div
                          style={{
                            padding: '1rem',
                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                            borderRadius: '10px',
                            marginLeft: '15px',
                            display: 'flex',
                            justifyContent: 'start',
                            flex: '100%',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}>
                          <div>
                            <img
                              src="/calendar.png"
                              alt="calendar"
                              style={{
                                width: '20px',
                                height: '20px',
                                verticalAlign: 'sub',
                              }}
                            />
                            <span>
                              {' '}
                              {`${moment(date?.from, 'HH:mm:ss').format(
                                'h:mm',
                              )} - ${moment(date?.to, 'HH:mm:ss').format(
                                'h:mm A',
                              )}`}{' '}
                            </span>
                          </div>
                          <span style={{marginLeft: '15px'}}>
                            {' '}
                            {moment(date?.date).format('DD MMM')}{' '}
                          </span>
                          {/* {(thisSlotExistInCart(
                            date?.consultation_session_id,
                          ) ||
                            date?.reserved == 1) && (
                            <span style={{marginLeft: 40}}>
                              {thisSlotExistInCart(
                                date?.consultation_session_id,
                              )
                                ? 'inCart'
                                : date?.reserved == 1
                                ? 'Booked'
                                : null}
                            </span>
                          )} */}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100">
            <MainButton
              disabled={removedSlots.length == 0}
              text="Delete"
              onClick={() => {
                setShowDeleteFutureSessions(false);
                setDeleteType('futureSpecificSlot');
                setShowDeleteAllAvailability(true);
              }}
            />
          </div>
          <div
            className="w-100"
            onClick={() => setShowDeleteFutureSessions(false)}>
            <CancelButton text="Back" />
          </div>
        </Modal.Footer>
      </Modal>

      {/*Confirm delete future sessions*/}
      <Modal
        show={confirmDeleteFutureSession}
        onHide={() => setConfirmDeleteFutureSession(false)}
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              You will delete all unbooked sessions and you must cancel any
              booked sessions to be able to delete it.
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton text={'Delete'} />
          </div>
          <div
            onClick={() => setConfirmDeleteFutureSession(false)}
            className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={emptyDurationsModal} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <h3 className="w-100 mb-4 font-weight-bold">
              You must select sessions period and price first
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={'Set Now'}
              onClick={() => {
                navigate('/consultant/add-availability-settings');
              }}
            />
          </div>
          <div onClick={() => setEmptyDurationsModal(false)} className="w-100">
            <CancelButton text={'Cancel'} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConsultantAvailability;
