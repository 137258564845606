import Card from 'react-bootstrap/Card';
import consultantImg from '../style/consultant.jpg';
import RateStars from '../../../UI/rate-stars/RateStars';
import '../style/ConsultantCard.css';
import {useNavigate} from 'react-router-dom';
import {ConsultantItemProps} from '../interfaces/IConsultants';
import classes from '../style/ConsultantCardHorizontal.module.css';
import { useTranslation } from 'react-i18next';

function ConsultantCard({consultant}: ConsultantItemProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  function handleClick() {
    console.log('dffsjfhkdhgkfdhgjfdgkfd', consultant);

    navigate(
      `/consultants/${consultant?.consultation_info_type}/${consultant?.id}`,
    );
  }
  const fields = consultant?.consultation_info_fields.slice(0,2)
  //const languages_names = consultant?.languages_names.split(', ')
  return (
    <>
    <Card className="list-item-card mentor consultant-card position-relative" onClick={handleClick}>
      <p className='top-card-label'>
        {consultant?.first_session_free == 1 && (
        <span className='price-label'>
        {t('First Session Free')}
        </span>
        )}

        <span className='subscribed-label'>
        {consultant?.available_sessions} {t('available')}
        </span>

        {/*
        <span className='type-label'>
          {consultant?.consultation_info_type == 'organization'
            ? 'mentor'
            : consultant?.consultation_info_type}
        
        </span>
        */}

      </p>
      <Card.Img
        variant="top"
        src={consultant?.avatar ?? consultantImg}
        className="ConsAvatar"
      />
      <Card.Body className="mt-3 mb-1">
        <Card.Title className="card-custom-title card-title h5">
          {consultant?.username}
        </Card.Title>

        <Card.Title>
          {fields?.map((l:any, i:any) => (
            <span style={{fontSize: '17px'}} className="card-custom-specialize" key={i}>
              {l.title} {i + 1 < fields?.length ? ' . ' : ''}
            </span>
          ))}

        </Card.Title>

        <div className="d-flex mb-2 mt-3 align-items-center">
          <div
            className={`d-flex mb-1 align-items-center`}>
            {consultant?.consultation_info_languages?.slice(0, 2).map((l, i) => (
              <span className='card-lang' key={i}>{l.name}, </span> 
            ))}

            {consultant?.consultation_info_languages && consultant?.consultation_info_languages?.length > 2 && (
              <p>+{consultant?.consultation_info_languages?.length - 2}</p>
            )}
          </div>
        </div>

        

        

        {/*
        <Card.Text className="mb-2 consultant-rate">
          <RateStars rateValue={consultant?.ratings} />
        </Card.Text>
        */}
       
      </Card.Body>
    </Card>
      <Card className="list-item-card text-center d-none" onClick={handleClick}>
        <Card.Img
          variant="top"
          src={consultant?.avatar ?? consultantImg}
          className="ConsAvatar"
        />
        <Card.Body className="mt-3 mb-1">
          <Card.Title className="consultant-card-title">
            {consultant?.username}
          </Card.Title>

          {/*
          <Card.Title className="consultant-card-title">
          {
          fields?.map((l, i) => <span style={{fontSize:'17px'}} className="secondry-color" key={i}>{l} { (i + 1) < fields?.length ? ' . ' : '' }</span>)
          }
          </Card.Title>
          */}

          {/*

          <div className="d-flex mb-2 mt-3 align-items-center justify-content-center">
            <div className={`${classes.Languages} d-flex mb-1 align-items-center`}>
              {
                languages_names?.slice(0,2).map((l, i) => <span key={i}>{l}</span>)
              }

              {
              languages_names && languages_names?.length > 2 && ( 
              <p>+{languages_names?.length - 2 }</p>
              ) }

              </div>
            
            
            </div>
            */}

          {/*
          <Card.Text className="mb-2 consultant-rate">
            <RateStars rateValue={consultant?.ratings} />
          </Card.Text>
          */}
          {consultant?.first_session_free == 1 && (
            <Card.Text>
              <span className="mt-2 availabilty-number">First Session Free</span>
            </Card.Text>
          )}
          <Card.Text>
            <span className="availabilty-number mt-2">
              {consultant?.available_sessions} available
            </span>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default ConsultantCard;
