export const getChatDomain = () => {
  
    let chatDomainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'dev.hedaya.ca' ){
        chatDomainName = 'DEV'
    }

    else if( hostName == 'test.hedaya.ca' ){
        chatDomainName = 'TEST'
    }

    else if( hostName == 'prod.hedaya.ca' || hostName == 'hedaya.ca' ){
        chatDomainName = 'Chats'
    }
    
    else{
        chatDomainName = 'DEV'
    }
    
    
    return chatDomainName
  
};
