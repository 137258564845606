// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth} from 'firebase/auth';
import {getDatabase} from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDtW8n_sEtVb83iRTLSvHMaj5hw27K03wQ",
  authDomain: "hedaya-fe022.firebaseapp.com",
  databaseURL: "https://hedaya-fe022-default-rtdb.firebaseio.com",
  projectId: "hedaya-fe022",
  storageBucket: "hedaya-fe022.appspot.com",
  messagingSenderId: "446369040665",
  appId: "1:446369040665:web:939091e640ade633189d88",
  measurementId: "G-55DBR20P08"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
