import {Fragment, useEffect, useState} from 'react';
import '../../Courses/style/Courses.css';
import {useParams} from 'react-router-dom';
import {getConsultantFollowUpSysList} from '../Consultants.service';
import ItemsPagination from '../../../UI/pagination/ItemsPagination';
import {Col, Row} from 'react-bootstrap';
import {ConsultantCoursesAndTrackingProp} from '../interfaces/IConsultants';
import {setLoading} from '../../../store/userSlice';
import {useAppDispatch} from '../../../hooks/hooks';
import TrackingCard from '../../Initiative/components/TrackingCard';
import { TrackingType } from '../../Initiative/interfaces/ITrackings';

const ConsultantFollowUpSys = ({tab}: ConsultantCoursesAndTrackingProp) => {
  let {id} = useParams();
  const dispatch = useAppDispatch();
  const [tracking, setTracking] = useState([]);
  const [lastPage, SetLastPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (tab == 'follow-up-systems') {
      getData();
    }
  }, [page, tab]);

  const getData = async () => {
    dispatch(setLoading(true));
    let result = await getConsultantFollowUpSysList(page, id!);

    if (result.response) {
      setTracking(result.response.data.data);
      SetLastPage(result.response.data.page_meta.lastPage);
      dispatch(setLoading(false));
    }
  };
  return (
    <Fragment>
      <Row className="courses-list">
        {tracking.map((track: TrackingType) => {
          return (
            <Col lg={6} className='p-0'>
                    <TrackingCard
                      tracking={track}
                      isPackage={track.tracking_type == 'multi'}
                      enrolled={track.is_enrolled!}
                      is_discover={track.selected_enrolled_user_id ? 1 : 0 }
                    />
                  </Col>
                );
              })}
      </Row>
      {tracking.length !== 0 && (
        <div className="pagination-container">
          <ItemsPagination
            pages={lastPage}
            current={page}
            changePage={page => {
              setPage(page);
            }}
          />
        </div>
      )}

      {tracking.length == 0 && (
        <div className='text-center'>
        <img src="/logo.png" alt="img" width={90} />
        <h3 className="text-center mt-3">Sorry There are no data found</h3>
      </div>
      )}
    </Fragment>
  );
};
export default ConsultantFollowUpSys;
