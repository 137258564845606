import {Col, Container, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/ContactUs.module.css';
import {useState} from 'react';
import {ContanctNurabi} from '../Consultants/Consultants.service';
import toast, {Toaster} from 'react-hot-toast';

const ContactUs = () => {
  const [message, setMessage] = useState('');

  const contactUsFn = async (message: string) => {
    if (message != '') {
      let data = new FormData();
      data.append('message', message);
      try {
        let result = await ContanctNurabi(data);
        console.log(result);

        if (result.response) {
          toast.success('Done');
          setMessage('');
        }
      } catch (e) {}
    }
  };
  return (
    <div className='mb-5'>
      <div className="contact-header d-flex justify-content-center align-items-center">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xs={6} className="d-flex justify-content-center align-items-center">
                <div className="breadcrumb d-flex justify-content-center">
                  <h3 className="breadcrumb-header">Contact Us</h3>
                  <div className="breadcrumb-details text-center">
                    <span>Home</span> <span> - </span>{' '}
                    <span>Contact Us</span>
                  </div>
                </div>
              </Col>

              
            </Row>
          </Container>
        </div>
      <Container className={classes.FormContainer}>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-center">
        <Col md={4} className='text-center'>
            <img src="/contact-us.png" alt="img" className={classes.Background} />
          </Col>

          <Col md={6}>
            <div className={classes.FormPadding}>
              <h3 className={`fontWeight-bold ${classes.HeaderTitle} `}>
                Get In <span>Touch</span>
              </h3>

              <div className="mt-3 mb-3">
                <Form.Control
                  className={`form-control m-0 ${classes.TextArea}`}
                  as="textarea"
                  rows={5}
                  onChange={e => setMessage(e.target.value)}
                  value={message}
                  placeholder='Your Message'
                />
                <p className={classes.ValidationMessage}>
                  Enter At Least 30 Character
                </p>
              </div>

              <div className="mt-4 mb-2">
                <MainButton text="Send Message" onClick={contactUsFn} />
              </div>

              <div className={`${classes.Contacts} text-center`}>
                
                <div className={`d-flex justify-content-between flex-wrap`}>
                  {/* <a href="/" target="_blank">
                    <img
                      src="/social/telephone-call.png"
                      alt="telephone-call"
                    />
                  </a> */}
                  <a href="https://wa.me/+16478059910" className='contact-item' target="_blank">
                    <div className='d-flex justify-content-center align-items-center'>
                      <img src="/contact/calling.png" alt="whatsapp" />
                      <div>
                        <p>PHONE</p>
                        <p>+16478059910</p>
                      </div>
                    </div>
                  </a>

                  <a href="#" className='contact-item' target="_blank">
                    <div className='d-flex justify-content-center align-items-center'>
                      <img src="/contact/message.png" alt="whatsapp" />
                      <div>
                        <p>ADDRESS</p>
                        <p>40 Frances Ave, Hamilton, Canada</p>
                      </div>
                    </div>
                  </a>


                  <a href="mailto:hedaya.platform@gmail.com" className='contact-item' target="_blank">
                    <div className='d-flex justify-content-center align-items-center'>
                      <img src="/contact/message.png" alt="whatsapp" />
                      <div>
                        <p>EMAIL</p>
                        <p>hedaya.platform@gmail.com</p>
                      </div>
                    </div>
                  </a>

                  
                  
                </div>

                
                {/*
                <div className={`d-flex justify-content-center`}>
                  <a
                    href="https://www.facebook.com/nualim.platform"
                    target="_blank">
                    <img src="/social/facebook.png" alt="facebook" />
                  </a>

                  <a
                    href="https://www.instagram.com/nualim.platform"
                    target="_blank">
                    <img src="/social/instagram.png" alt="intagram" width={40} />
                  </a>
                  
                </div>
                */}
              </div>
            </div>
          </Col>

          
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
