import { FaCopy, FaFacebook, FaInstagram, FaLinkedin, FaRegCopy, FaShareAlt, FaTwitter, FaUserPlus, FaWhatsapp } from "react-icons/fa"
import ActionButton from "../../UI/action-button/ActionButton"
import { Dropdown } from "react-bootstrap"
import "./ShareIcon.css"
import toast, {Toaster} from 'react-hot-toast';
import { useEffect, useState } from "react";

const ShareIcon = ({url,hasInvite=false,copyLink=false,hasIcon=false}) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleWidthChange = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addListener(handleWidthChange);
    
    return () => {
      mediaQuery.removeListener(handleWidthChange);
    };
  }, []);

    // Get the current URL
    var currentUrl = window.location.href;

    // Encode the URL
    var encodedUrl = encodeURIComponent(currentUrl);

    const handleCopyClick = () => {
      toast.success('copied');
      // Create a temporary input element to copy the value
      const tempInput = document.createElement('input');
      tempInput.value = url ?? encodedUrl;
      document.body.appendChild(tempInput);
  
      // Select and copy the value
      tempInput.select();
      document.execCommand('copy');
      
      // Remove the temporary input
      document.body.removeChild(tempInput);
    };

    const handleCopyLink = () => {
     
    // Get the current URL
    var url = window.location.href;
    
    // Copy the URL to clipboard
    navigator.clipboard.writeText(url)
    .then(function() {
        console.log('URL copied to clipboard');
        toast.success('copied');
    })
    .catch(function(err) {
        console.error('Failed to copy: ', err);
        // Provide fallback for browsers not supporting clipboard API
    });
    };

    return (
     <>
     
    
        <div className="shareDropdown">
        <Dropdown>
      <Dropdown.Toggle variant="customSocialShareDropDown" id="dropdown-custom">
      <ActionButton>
      { hasIcon ? <img src="/share-ico.svg" alt="img" width={'25'} /> : <img src="/sharing.svg" alt="img" className="shareDropdownBtn" /> }
        
    </ActionButton>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href={`https://www.facebook.com/sharer/sharer.php?u=${url ?? encodedUrl}`} target="_blank">
            <FaFacebook size={20} /> <div className="social-name">Facebook</div>
        </Dropdown.Item>

        <Dropdown.Item href={`https://twitter.com/share?url=${url ?? encodedUrl}`} target="_blank">
            <FaTwitter size={20} /> <div className="social-name">Twitter</div>
        </Dropdown.Item>

        <Dropdown.Item href={`https://www.linkedin.com/sharing/share-offsite/?url=${url ?? encodedUrl}`} target="_blank">
            <FaLinkedin size={20} /> <div className="social-name">Linkedin</div>
        </Dropdown.Item>

        

        {isMobile ? (
          <Dropdown.Item href={`whatsapp://send?text=${url ?? encodedUrl}`} target="_blank">
              <FaWhatsapp size={20} /> <div className="social-name">Whatsapp</div>
          </Dropdown.Item>
      ) : (
        ''
      )}

        {hasInvite ? (
        <Dropdown.Item  onClick={handleCopyClick}>
            <FaRegCopy size={20} /> <div className="social-name">Copy invitation Link</div>
        </Dropdown.Item>
        ) : (
          ''
        )}

        {copyLink ? (
        <Dropdown.Item  onClick={handleCopyLink}>
            <FaRegCopy size={20} /> <div className="social-name">Copy Link</div>
        </Dropdown.Item>
        ) : (
          ''
        )}

        
      </Dropdown.Menu>
    </Dropdown>
    </div>
       
     </>   
    )
}


export default ShareIcon