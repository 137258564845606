import {Fragment, useEffect, useState} from 'react';
import '../../Courses/style/Courses.css';
import {useParams} from 'react-router-dom';
import {getConsultantCoursesList} from '../Consultants.service';
import CourseItem from '../../Courses/components/CourseItem';
import ItemsPagination from '../../../UI/pagination/ItemsPagination';
import {useAppDispatch} from '../../../hooks/hooks';
import {setLoading} from '../../../store/userSlice';
import {ConsultantCoursesAndTrackingProp} from '../interfaces/IConsultants';
import ListCourseCard from '../../../UI/ListCourseCard';
import { Col, Row } from 'react-bootstrap';

const ConsultantCourses = ({tab}: ConsultantCoursesAndTrackingProp) => {
  let {id} = useParams();
  const dispatch = useAppDispatch();
  const [courses, setCourses] = useState([]);
  const [lastPage, SetLastPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (tab == 'courses') {
      console.log('tabtab', tab);
      getData();
    }
  }, [page, tab]);

  const getData = async () => {
    dispatch(setLoading(true));

    let result = await getConsultantCoursesList(page, id!);

    if (result.response) {
      setCourses(result.response.data);
      SetLastPage(result.response.meta.lastPage);
    }

    dispatch(setLoading(false));
  };

  return (
    <Fragment>
      <div className="courses-list">
        <Row>
        {courses.map(course => {
          return ( <Col lg="6" className='card-item-container'> 
                      <ListCourseCard course={course} />
                    </Col>
                    )
        })}
        </Row>
        
      </div>
      {courses.length !== 0 && (
        <div className="pagination-container">
          <ItemsPagination
            pages={lastPage}
            current={page}
            changePage={page => {
              setPage(page);
            }}
          />
        </div>
      )}
      {courses.length == 0 && (
        <div className='text-center'>
        <img src="/logo.png" alt="img" width={90} />
        <h3 className="text-center mt-3">Sorry There are no data found</h3>
      </div>
      )}
    </Fragment>
  );
};
export default ConsultantCourses;
