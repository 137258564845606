import {urlParamReplace} from '../../helpers/UrlParamReplace';
import {APIURL} from '../../services/http/api';
import {EApiParams} from '../../services/http/apiParams.enum';
import {HttpService} from '../../services/http/http.service';

const http = new HttpService();

export const getAvailability = async (date: string) => {
  const api = APIURL.availability.getAvailability;
  const finalApi = urlParamReplace(api, {[EApiParams.date]: date});

  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};

export const sendBlocks = async (data: FormData) => {
  console.log('datadatadatadata,data', data);

  const api = APIURL.availability.setAvailability;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};

export const deleteSlotsByIds = async (data: string) => {
  const api = APIURL.availability.deleteSlotsByIds;
  const finalApi = urlParamReplace(api, {[EApiParams.id]: data});
  const [err, resp] = await http.delete(finalApi);

  return {error: err, response: resp?.data};
};

export const deleteRangeDate = async (start: string, end: string) => {
  const api = APIURL.availability.deleteRange;
  const finalApi = urlParamReplace(api, {
    [EApiParams.start]: start,
    [EApiParams.end]: end,
  });
  const [err, resp] = await http.delete(finalApi);

  return {error: err, response: resp?.data};
};

export const setPrices = async (data: FormData) => {
  const api = APIURL.availability.setPrices;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};

export const getPrices = async () => {
  const api = APIURL.availability.getCurrency;

  const [err, resp] = await http.get(api);

  return {error: err, response: resp?.data};
};


export const getConsultationInfosMeetingLink = async () => {
  const api = APIURL.availability.getMeetLink;

  const [err, resp] = await http.get(api);

  return {error: err, response: resp?.data};
};

export const setAvailabilityStatus = async (data: FormData) => {
  const api = APIURL.availability.setAvailabilityStatus;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};

export const setMeetLinkActivitation = async (data: FormData) => {
  const api = APIURL.availability.storeMeetLink;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {error: err, response: resp?.data};
};

export const getAvailabilityStatus = async () => {
  const api = APIURL.availability.getAvailabilityStatus;
  const [err, resp] = await http.get(api);

  return {error: err, response: resp?.data};
};

export const getSpecAvailability = async (duration: string, time: string) => {
  const api = APIURL.availability.getSpecAvalability;
  const finalApi = urlParamReplace(api, {
    [EApiParams.time]: time,
    [EApiParams.duration]: duration,
  });

  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};
